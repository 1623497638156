import React from 'react';
import { Layout } from '../components/common';
import { ProcessHero } from '../components/process';

const ProcessPage = () => {
  return (
    <Layout
      seo={{
        title: 'HSUP Folyamat',
        lang: 'hu',
        description: 'This is a barebones starter for Gatsby'
      }}
      baseRoute="/folyamat"
    >
      <ProcessHero />
    </Layout>
  );
};

export default ProcessPage;
