import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

const ProcessHero = () => {
  const {
    illustration,
    hsup,
    firstSemester,
    secondSemester
  } = useStaticQuery(graphql`
    query {
      illustration: file(relativePath: { eq: "bg_process.png" }) {
        childImageSharp {
          fluid(maxWidth: 619, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      hsup: file(relativePath: { eq: "bg_hsup.png" }) {
        childImageSharp {
          fluid(maxWidth: 201, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      firstSemester: file(relativePath: { eq: "semester_first.png" }) {
        childImageSharp {
          fluid(maxWidth: 1224, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      secondSemester: file(relativePath: { eq: "semester_second.png" }) {
        childImageSharp {
          fluid(maxWidth: 1224, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <section className="p-1 relative bg-snow ">
      <div
        style={{
          background:
            'linear-gradient(180deg, rgba(245,246,249,1) 0%, rgba(255,255,255,1) 100%)'
        }}
      >
        <div className="flex my-10 md:flex-row flex-col items-center xl:container relative">
          <div className="hidden md:block md:w-1/2">
            <Img
              className="mr-auto"
              alt="Emelkedj ki a tömegből!"
              fluid={illustration.childImageSharp.fluid}
              style={{ maxWidth: 619 }}
            />
          </div>
          <div className="md:w-1/2 py-12 md:py-24 flex flex-col md:text-left text-center mt-10 mb:mt-0 z-20">
            <h1>
              Emelkedj ki a tömegből a Hungarian Startup University Programmal!
            </h1>
            <h3 className="py-8">
              A HSUP egy két féléves e-learning tárgy, amelynek első félévében
              az innovatív gondolkodásmód és a startup világ megismerése kerül a
              fókuszba, míg a második félévben a vállalkozások felépítésével
              kapcsolatos gyakorlati tudást sajátíthatod el.
            </h3>
          </div>
          <div className="absolute hidden pl-12 pt-56 right-0 w-1/5 xl:block z-10">
            <Img
              className="ml-auto"
              alt="Szemptemberben startol a Hungarian Startup University Program"
              fluid={hsup.childImageSharp.fluid}
              style={{ maxWidth: 201 }}
            />
          </div>
        </div>
      </div>

      <div className="container items-center lg:items-start justify-center flex flex-col lg:flex-row sm:p-8">
        <Img
          className="w-11/12 md:8/12 lg:w-5/12 my-4  sm:m-8"
          alt="Szemptemberben startol a Hungarian Startup University Program"
          fluid={firstSemester.childImageSharp.fluid}
          style={{ minWidth: 200, maxWidth: 619 }}
        />
        <Img
          className="w-11/12 md:8/12 lg:w-5/12 my-4 sm:m-8"
          alt="Szemptemberben startol a Hungarian Startup University Program"
          fluid={secondSemester.childImageSharp.fluid}
          style={{ minWidth: 200, maxWidth: 619 }}
        />
      </div>
    </section>
  );
};

export default ProcessHero;
